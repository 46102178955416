const ADD_SUPPLIER_PAYMENT = "ADD_SUPPLIER_PAYMENT";
const UPDATE_SUPPLIER_PAYMENT = "ADD_SUPPLIER_PAYMENT";
const DELETE_SUPPLIER_PAYMENT = "DELETE_SUPPLIER_PAYMENT";
const SUPPLIERS_PAYMENT = "SUPPLIERS_PAYMENT";
const ADD_SUPPLIER_PAYMENT_ERROR = "ADD_SUPPLIER_PAYMENT_ERROR";
const SUPPLIER_DETAILS_PAYMENT = "SUPPLIER_DETAILS_PAYMENT";

export {
	ADD_SUPPLIER_PAYMENT,
	UPDATE_SUPPLIER_PAYMENT,
	DELETE_SUPPLIER_PAYMENT,
	SUPPLIERS_PAYMENT,
	ADD_SUPPLIER_PAYMENT_ERROR,
	SUPPLIER_DETAILS_PAYMENT,
};
