const ADD_PRODUCT_BRAND = "ADD_PRODUCT_BRAND";
const UPDATE_PRODUCT_BRAND = "ADD_PRODUCT_BRAND";
const DELETE_PRODUCT_BRAND = "DELETE_PRODUCT_BRAND";
const PRODUCT_BRANDS = "PRODUCT_BRANDS";
const ADD_PRODUCT_BRAND_ERROR = "ADD_PRODUCT_BRAND_ERROR";
const PRODUCT_BRAND_DETAILS = "PRODUCT_BRAND_DETAILS";

export {
	ADD_PRODUCT_BRAND,
	UPDATE_PRODUCT_BRAND,
	DELETE_PRODUCT_BRAND,
	PRODUCT_BRANDS,
	ADD_PRODUCT_BRAND_ERROR,
	PRODUCT_BRAND_DETAILS,
};
