const ADD_TRANSACTION = "ADD_TRANSACTION";
const DELETE_TRANSACTION = "DELETE_TRANSACTION";
const TRANSACTIONS = "TRANSACTIONS";
const ADD_TRANSACTION_ERROR = "ADD_TRANSACTION_ERROR";
const TRANSACTION_DETAILS = "TRANSACTION_DETAILS";

export {
	ADD_TRANSACTION,
	DELETE_TRANSACTION,
	TRANSACTIONS,
	ADD_TRANSACTION_ERROR,
	TRANSACTION_DETAILS,
};
