const ADD_SUPPLIER = "ADD_SUPPLIER";
const UPDATE_SUPPLIER = "ADD_SUPPLIER";
const DELETE_SUPPLIER = "DELETE_SUPPLIER";
const SUPPLIERS = "SUPPLIERS";
const ADD_SUPPLIER_ERROR = "ADD_SUPPLIER_ERROR";
const SUPPLIER_DETAILS = "SUPPLIER_DETAILS";

export {
  ADD_SUPPLIER,
  UPDATE_SUPPLIER,
  DELETE_SUPPLIER,
  SUPPLIERS,
  ADD_SUPPLIER_ERROR,
  SUPPLIER_DETAILS,
};
